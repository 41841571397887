import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cn from 'classnames'
import { toast } from 'react-toastify'

interface AppClipboardProps {
  text: string;
  className?: string;
}

function AppClipboard({ className, text }: AppClipboardProps) {
  /**
   * Copy text to clipboard
   */
  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(text)
    toast('Copied to clipboard')
  }

  return (
    <FontAwesomeIcon
      className={cn(
        'ms-2 cursor-pointer text-500 hover:text-secondary',
        className
      )}
      icon="copy"
      onClick={copyToClipboard}
    />
  )
}

export default AppClipboard
