import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormEvent, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import ItineraryContent from 'modules/simple-crm/components/itinerary/ItineraryContent'
import cn from 'classnames'
import itineraryService from 'services/itinerary.service'

export default function ToolRefreshItinerary() {
  const [refreshBy, setRefreshBy] = useState('Cruise ID')
  const [cruiseId, setCruiseId] = useState('')
  const [cruiseIds, setCruiseIds] = useState([])
  const [itinCode, setItinCode] = useState('')
  const [refreshing, setRefreshing] = useState(false)
  const [searching, setSearching] = useState(false)
  const [done, setDone] = useState(false)

  /**
   * Resets the form fields when the refreshBy state changes.
   */
  useEffect(() => resetForm(), [refreshBy])

  const searchForCruises = async (e: FormEvent) => {
    e.preventDefault()

    setCruiseIds([])
    setSearching(true)

    const { data }: any = await itineraryService.getCruisesByItinCode(itinCode)

    setSearching(false)

    setCruiseIds(data?.cruise_ids || [])
  }

  /**
   * Refreshes the itinerary segments for a given cruise ID.
   */
  const handleIndividualRefresh = async (cruiseId: string) => {
    setCruiseId(cruiseId)
    handleRefresh(cruiseId)
  }

  /**
   * Handles the form submission for refreshing itinerary segments.
   */
  const handleRefreshSubmit = (e: FormEvent) => {
    e.preventDefault()
    handleRefresh(cruiseId)
  }

  /**
   * Refreshes the itinerary segments for a given cruise ID.
   */
  const handleRefresh = async (cruiseId: string) => {
    setItinCode('')
    setRefreshing(true)
    setDone(false)

    const { data, error }: any =
      await itineraryService.refreshSegmentsByCruiseId(cruiseId)

    setRefreshing(false)

    if (error) {
      toast(`Failed to refresh itinerary for Cruise ID ${cruiseId}`, {
        type: 'error',
      })

      return
    }

    setDone(true)
    toast('Itinerary successfully refreshed')
    setItinCode(data.itinerary_code)
  }

  /**
   * Clear the form fields.
   */
  const resetForm = () => {
    setItinCode('')
    setCruiseId('')
    setCruiseIds([])
    setDone(false)
  }

  return (
    <div>
      <div className="d-flex gap-2">
        <button
          disabled={refreshing || searching}
          onClick={() => setRefreshBy('Cruise ID')}
          className={cn(
            'btn',
            refreshBy === 'Cruise ID' ? 'btn-primary' : 'btn-light'
          )}
        >
          Use Cruise ID
        </button>
        <button
          disabled={refreshing || searching}
          onClick={() => setRefreshBy('Itinerary Code')}
          className={cn(
            'btn',
            refreshBy === 'Itinerary Code' ? 'btn-primary' : 'btn-light border'
          )}
        >
          Use Itinerary Code
        </button>
      </div>
      {refreshBy === 'Cruise ID' && (
        <div className="mt-4">
          <p className="fw-semibold">
            Refresh itinerary segments for a given cruise id
          </p>
          <form
            className="d-flex gap-2 align-items-end"
            onSubmit={handleRefreshSubmit}
          >
            <div className="flex-1">
              <label className="control-label">Cruise ID</label>
              <input
                type="text"
                value={cruiseId}
                onChange={(e) => setCruiseId(e.target.value)}
                className="form-control"
                placeholder="Enter Cruise ID"
              />
            </div>
            <div>
              <div className="d-flex justify-content-end mt-2 gap-2">
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={!cruiseId || refreshing}
                >
                  <FontAwesomeIcon icon="sync-alt" className="me-2" />
                  {refreshing ? 'Refreshing...' : 'Refresh'}
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
      {refreshBy === 'Itinerary Code' && (
        <div className="mt-4">
          <p className="fw-semibold">
            Search for cruise ids using an itinerary code
          </p>
          <form
            className="d-flex gap-2 align-items-end"
            onSubmit={searchForCruises}
          >
            <div className="flex-1">
              <label className="control-label">Itinerary code</label>
              <input
                type="text"
                value={itinCode}
                onChange={(e) => setItinCode(e.target.value)}
                className="form-control"
                placeholder="Enter Itinerary Code"
              />
            </div>
            <div>
              <div className="d-flex justify-content-end mt-2 gap-2">
                <button
                  type="submit"
                  className="btn btn-info"
                  disabled={!itinCode || searching}
                >
                  <FontAwesomeIcon icon="search" className="me-2" />
                  {searching ? 'Searching...' : 'Search'}
                </button>
              </div>
            </div>
          </form>
          <div className="mt-3">
            {!searching && !cruiseIds.length ? (
              <p className="fs-10 text-secondary my-3 text-center">
                No cruises to show.
              </p>
            ) : (
              !!cruiseIds.length && (
                <>
                  <label className="control-label">Cruises found:</label>
                  <ul className="list-group">
                    {cruiseIds.map((item: any) => (
                      <li className="list-group-item" key={item}>
                        <div className="d-flex justify-content-between align-items-center">
                          <span className="fw-medium">{item}</span>

                          <button
                            onClick={() => handleIndividualRefresh(item)}
                            type="button"
                            className="btn btn-sm btn-success"
                            disabled={
                              !item || (refreshing && item === cruiseId)
                            }
                          >
                            <FontAwesomeIcon icon="sync-alt" className="me-2" />
                            {refreshing && item === cruiseId
                              ? 'Refreshing...'
                              : 'Refresh'}
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <div className="d-flex justify-content-end mt-2 gap-2">
                    <button
                      disabled={refreshing || searching}
                      className="btn btn-light"
                      onClick={resetForm}
                    >
                      <FontAwesomeIcon icon="times" className="me-2" />
                      Clear
                    </button>
                  </div>
                </>
              )
            )}
          </div>
        </div>
      )}
      {done && (
        <div className="mt-3">
          <ItineraryContent cruiseId={cruiseId} itinCode={itinCode} />
        </div>
      )}
    </div>
  )
}
